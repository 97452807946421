* {
  box-sizing: border-box;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* Overall Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}

/* Scrollbar Track (The background) */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Scrollbar Thumb (The draggable handle) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

body {
  margin: 0;
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
.h1 {
  font-optical-sizing: auto;
  font-weight: bold;
  font-size: 30px;
  line-height: 43px;
  font-style: normal;
}
.h2 {
  font-optical-sizing: auto;
  font-weight: medium;
  font-size: 20px;
  line-height: 29px;
  font-style: normal;
}
.h3 {
  font-optical-sizing: auto;
  font-weight: medium;
  font-size: 11px;
  line-height: 16px;
  font-style: normal;
}
.body1 {
  font-optical-sizing: auto;
  font-weight: regular;
  font-size: 13px;
  line-height: 19px;
  font-style: normal;
}
.caption {
  font-optical-sizing: auto;
  font-weight: regular;
  font-size: 10.6px;
  line-height: 15px;
  font-style: normal;
}
.caption2 {
  font-optical-sizing: auto;
  font-weight: medium;
  font-size: 14px;
  line-height: 125%;
  font-style: normal;
}
.subtitle {
  font-optical-sizing: auto;
  font-weight: regular;
  font-size: 15.33px;
  line-height: 22px;
  font-style: normal;
}

/* Color */
.grey1-color {
  color: #b3b3b3;
}

/* Welcome page */
@keyframes carAnimation {
  0% {
    width: 20%;
    bottom: -89px;
    left: 60%;
    opacity: 1;
  }
  80% {
    width: 3%;
    bottom: 125px;
    left: 52%;
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.animate-car {
  animation: carAnimation 6s linear infinite;
}
